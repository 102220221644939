import React, { useContext, useState } from 'react';
import { SearchContext } from "./Context/SearchContext";
import PropTypes from 'prop-types';
import Rating from '../Elements/Rating';
import ClockIcon from '../Icons/ClockIcon';
import AwardIcon from '../Icons/AwardIcon';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import DynamicPrice from '../DynamicPrice';
import Distance from './Distance';

const propTypes = {
    result: PropTypes.object.isRequired,
};

const SearchResult = ({
    result: {
        title,
        subtitle,
        rating,
        total_ratings,
        duration_string,
        upcoming_event_dates,
        total_additional_events,
        total_additional_inperson_events,
        price,
        url,
        config,
        credits,
        thumbnail,
        format,
        location,
        all_locations,
        price_config,
        media_type,
        post_type,
        excerpt,
        training_type,
        card_description
    },
}) => {
    
    const [searchContext, setSearchContext] = useContext(SearchContext);

    if(url && training_type && training_type.includes("onsite") && upcoming_event_dates && upcoming_event_dates.length < 1 ) {
        url = url + '?onsite';
    }

    if( searchContext.formats && searchContext.formats.length == 1 && searchContext.formats[0] == 'in-person' ) {
        url = url + '?inperson=true';
    }

    return (
        <div
            className="search__result card card__course"
            typeof="Course"
            content="online"
        >
            <div className="card__content">

                <div className="card__header">

                    { config.title ? (
                        <h4 className="card__title u-text-black" property="name">
                            {title}
                        </h4>
                    ) : null }

                    { card_description ? (
                        <p className="card__description">{ card_description}</p>
                    ) : null }

                    { config.subtitle && subtitle ? (
                        <p className="card__title-sub">{ subtitle }</p>
                    ) : null }
                    
                    <div className="card__meta">
                        {total_ratings > 0 && config.ratings ? (
                            <div
                                className="card__ratings"
                            >
                                <div
                                    className="card__stars"
                                >
                                    <Rating rating={rating} total={5} />
                                </div>
                                <span>({total_ratings})</span>
                            </div>
                        ) : null}

                        {duration_string && config.duration ? (
                            <div className="card__duration">
                                <meta property="timeRequired" />
                                <ClockIcon />
                                <p className="u-small-text">
                                    {duration_string}
                                </p>
                            </div>
                        ) : null}

                        { training_type && training_type.includes("physical-product") && config.media_type && media_type  ? (
                            <p className="card__subtitle">
                                { media_type }
                            </p>
                        ) : null}

                    </div>

                    {credits && config.credits ? (
                        
                        <ul className="card__credits">
                            <li><AwardIcon /></li>
                            { Object.keys(credits).map((credit, idx) => {
                                    return (
                                        <li
                                            key={idx}
                                            className="card__credit"
                                        >
                                            {credit}: {credits[credit]}
                                        </li>
                                    );
                                })
                            }
                        </ul>

                    ) : null}

                    { post_type === 'post' && excerpt ? (
                        <div className="card__excerpt"><p>{ excerpt }</p></div>
                    ) : null }

                </div>

                { config.date || config.in_person.location  ? (
                    <div className="card__details">
                            
                        <div className="card__dates">

                            { training_type && training_type.includes("onsite") && upcoming_event_dates && upcoming_event_dates.length < 1 && config.onsite.card_text ? (
                                <p>{ config.onsite.card_text }</p>
                            ) : null }

                            { training_type && training_type.includes("onsite") && upcoming_event_dates == null && config.onsite.card_text ? (
                                <p>{ config.onsite.card_text }</p>
                            ) : null }
                            
                            { training_type && training_type.includes("physical-product") && config.physical_products.card_text ? (
                                <p>{ config.physical_products.card_text }</p>
                            ) : null }

                            { config.date ? (

                                <>
                                    { training_type && training_type.includes("onsite") && upcoming_event_dates && upcoming_event_dates.length < 1 ? null : (
                                        
                                        <>
                                        { searchContext.formats && searchContext.formats.includes('in-person') ? (
                                                <></>
                                            ) : (

                                        <div className="card__list card__list--virtual">
                                                                            
                                            { training_type && training_type.includes("webinars") && config.media_type && media_type  ? (
                                                <p className="card__subtitle">
                                                    { media_type }
                                                </p>
                                            ) : null}

                                            {format && upcoming_event_dates && upcoming_event_dates.length > 0 && config.format ? (
                                                <p className="card__subtitle">
                                                    { upcoming_event_dates.length == 1 ? (
                                                        <>{format.slice(0, -1)}</>
                                                    ) : (
                                                        <>{format}</>
                                                    )}
                                                :</p>
                                            ) : null}

                                            
                                                <div
                                                    className="card__events"
                                                    rel="hasCourseInstance"
                                                    typeof="CourseInstance"
                                                >
                                                    <ul className="card__events-list">

                                                        {upcoming_event_dates
                                                            ? upcoming_event_dates.map((date) => {
                                                                return (
                                                                    <li
                                                                        key={date.id}
                                                                        className="card__events-item"
                                                                    >
                                                                        <meta property="calendar" />
                                                                        <p className="u-small-text">
                                                                            {date.start_date}
                                                                        </p>
                                                                    </li>
                                                                );
                                                            })
                                                        : null}

                                                        {total_additional_events ? (
                                                            <li className="card__events-item card__events-item--more">
                                                                <meta property="calendar" />
                                                                <p className="u-small-text">
                                                                    + {total_additional_events} more dates
                                                                </p>
                                                            </li>
                                                        ) : null}
                                                    </ul>
                                                </div>
                                            

                                        </div>

) }</>



                                    )}
                                </>
                                
                            ) : null }

                            {location && location.length > 0 && upcoming_event_dates && config.in_person.location ? (
                                <div className="card__list card__list--in-person">
                                    
                                    { searchContext.formats && searchContext.formats.includes('in-person') ? null : (
                                        <p className="card__subtitle">{config.in_person.label}</p>
                                    ) }
                                    
                                    <ul className="card__locations">
                                        
                                        { searchContext.formats && searchContext.formats.includes('in-person') ? (
                                            <>
                                                {all_locations
                                                    ? all_locations.map((loc) => {
                                                        return (
                                                            <li
                                                                key={loc.id}
                                                                className="card__location-date"
                                                            >
                                                                <meta property="calendar" />
                                                                <span>{loc.start_date}</span> <span>{loc.city}, {loc.state}</span> <Distance to={[{ lng: loc.location.coordinates[0], lat: loc.location.coordinates[1] }]}/>
                                                            </li>
                                                        );
                                                    })
                                                : null}
                                            </>
                                        ) : (
                                            <>
                                                {location
                                                    ? location.map((loc) => {
                                                        return (
                                                            <li
                                                                key={loc.id}
                                                                className="card__location-date u-small-text"
                                                            >
                                                                <meta property="calendar" />
                                                                {loc.start_date}, {loc.city}, {loc.state}
                                                            </li>
                                                        );
                                                    })
                                                : null}

                                                {total_additional_inperson_events ? (
                                                    <li className="card__events-item card__events-item--more">
                                                        <meta property="calendar" />
                                                        <p className="u-small-text">
                                                            + {total_additional_inperson_events} more dates
                                                        </p>
                                                    </li>
                                                ) : null}
                                            </>
                                        )}

                                       

                                    </ul>

                                </div>
                            ) : null}

                        </div>
                            
                        { thumbnail && config.thumbnail ? (
                            <div className="card__media">
                                <img className="card__thumbnail" height="150" width="115" alt={`Training image for ${title}`} src={thumbnail}/>
                            </div>
                        ) : null }

                    </div>
                ) : null}

            </div>
            <div
                className="card__cost"
                rel="hasCourseInstance"
                typeof="CourseInstance"
            >
                
                { post_type === 'page' || post_type === 'post' ? null : (
                    <>
                        { config.price && price && searchContext.types ? (
                            <>  
                                { training_type && training_type.includes("onsite") && upcoming_event_dates && upcoming_event_dates.length < 1 ? null : (
                                    <div className="card__price" rel="offers" typeof="Offer">
                                        <meta property="priceCurrency" content="USD" />
                                        <p className="price">
                                            From <span property="price"><DynamicPrice config={price_config}/></span>/person
                                        </p>
                                    </div>
                                ) }
                            </>
                        ) : null }
                    </>
                
                ) }

                { config.view_button && url ? (
                    <div className="card__cta">
                        <button
                            className="button button--text-only button--text-only--no-hover button--icon button--icon-right button--small"
                            property="url"
                            href={url}
                        >
                            View info <ChevronRightIcon />
                        </button>
                    </div>
                ) : null }

            </div>

            <a
                className="card__link"
                aria-label={title}
                name={title}
                property="url"
                href={url}
            ></a>
        </div>
    );
};

SearchResult.propTypes = propTypes;

export default SearchResult;
